import $ from 'jquery'

function showP( el, xP, yP) {
    el.each(function () {
        let depth = parseFloat($(this).attr('depth'));
        const isRotate = $(this).attr('rotate') ? Number($(this).attr('rotate')) : 1
        const disableY = $(this).attr('movey') ? Number($(this).attr('movey')) : 1
        let boxW = $(this).width();
        let boxH = $(this).height();
        let style = function () {
            var str = ''
            if (isRotate) {
                str = `translate3d(${(-xP * boxW * 0.1) * depth}px, ${(-yP * 0.1 * boxH) * depth}px, ${depth*20}px) rotateY(${xP * -8}deg) rotateX(${yP * 8}deg)`
            } else if(!disableY) {
                str = `translate3d(${(-xP * boxW * 0.1) * depth}px, 0px, 0px)`
            } else {
                str = `translate3d(${(-xP * boxW * 0.1) * depth}px, ${(-yP * 0.1 * boxH) * depth}px, ${depth*20}px)`
            }
            return str
        }
        $(this).css({
            // 'transform-origin': `${xP < 0 ? '100%' : '0%'} ${yP < 0 ? '100%' : '0%'}`,
            'transform': style()
        })
    })
}

export default function parallax(option = {
    container:'body',
    el: 'body',
}) {
    
    const elBox = $(option.el); //容器盒子
    const preBox = $(option.el).find("[depth]") //变换的盒子
    //data-depth 深度
    //设置变换盒子的属性
    preBox.each(function () {
        $(this).css({
            'transform-origin': '50%',
            'transition': 'transform .3s linear',
        })
    });
    elBox.css({
        'perspective':'5000px'
    })
    // //console.log(document.querySelector(option.container));
    document.querySelector(option.container).onmousemove = function (evt) {
        // //console.log(evt);
        // e = evt || window.event;
        let e = evt;
        let thisPX = elBox[0].getBoundingClientRect().left + (elBox.width() / 2) //盒子中心坐标x
        let thisPY = elBox[0].getBoundingClientRect().top + (elBox.height() / 2) //盒子中心坐标y
        
        let xP = (thisPX - e.pageX) / thisPX
        let yP = (thisPY - e.pageY) / thisPY
    
        showP( preBox, xP, yP);
    }
}