import './index.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Mousewheel, EffectFade } from 'swiper'
import parallax from '../../assets/js/parallax'
import 'swiper/css'
import 'swiper/css/effect-fade'
import React from 'react'


class IndexView extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            textVal: 0,
            isPlayMusic: false
        }
    }
    initSwiper() {
        parallax({
            container: 'body',
            el: '.swiper-slide'
        })
    }
    changeSlide(e) {
        if (e.realIndex === 7) {
            var videoId = document.getElementById('zmVideo')
            if (videoId.currentTime === 0) {
                videoId.play()
            }  else if (videoId.currentTime === videoId.duration){
                videoId.currentTime = 0
                videoId.play()
            }
        }
    }
    moveTextScreen(e) {
        const moveVal = {
            left: 90,
            center: 0,
            right: -90
        }
        let currPosition = '' // left center right
        const screenWidth = window.innerWidth
        const posVal = screenWidth / 3
        const isLeft = e.clientX > 0 && e.clientX < posVal
        const isCenter = e.clientX > posVal && e.clientX < posVal * 2
        const isRight = e.clientX > posVal * 2 && e.clientX <  screenWidth
        
        if (isLeft) {
            currPosition = 'left'
        } else if (isCenter) {
            currPosition = 'center'
        } else if (isRight) {
            currPosition = 'right'
        }
        // console.log(currPosition)
        this.setState({
            textVal: moveVal[currPosition]
        })
    }
    tirggerMusic() {
        const bgm = document.getElementById('bgmMusic')
        let objState = {
            isPlayMusic: false
        }
        if (bgm.paused) {
            bgm.play()
            objState.isPlayMusic = true
        } else {
            bgm.pause()
            objState.isPlayMusic = false
        }
        this.setState(objState)
    }

    render() {
        // console.log(this.state.textVal)
        return (
            <>
            <audio id="bgmMusic" src='https://lzj.maliao.online/bgm.mp3' preload="auto" loop={true}></audio>
            <div className={['music-btn', this.state.isPlayMusic ? '' : 'stop'].join(' ')} onClick={() => this.tirggerMusic()}></div>
            <Swiper
                modules={[Mousewheel, EffectFade]}
                effect="fade"
                className='main-tab'
                direction="vertical"
                mousewheel={{
                    sensitivity: 0.1,
                    thresholdTime: 1000
                }}
                onInit={() => this.initSwiper()}
                onSlideChange={ e => this.changeSlide(e)}
            >
                <SwiperSlide className='page-style screen0'>
                    <div className='text offset-el' depth="0.1"></div>
                    <div className='number2 offset-el' depth="0.7"></div>
                </SwiperSlide>
                <SwiperSlide className='page-style screen1'></SwiperSlide>
                <SwiperSlide className='page-style screen2'></SwiperSlide>
                <SwiperSlide className='page-style screen3'></SwiperSlide>
                <SwiperSlide className='page-style screen4'>
                    <video src='https://lzj.maliao.online/lxtz.mp4' muted={true} loop={true} autoPlay={true}></video>
                </SwiperSlide>
                <SwiperSlide className='page-style screen5' onMouseMove={e => this.moveTextScreen(e)}>
                    <div className='superman-logo'></div>
                    <div className='superman-text' style={{
                        transform: 'rotateY(' + this.state.textVal + 'deg)'
                    }}>
                        <div className='text-left'></div>
                        <div className='text-center'></div>
                        <div className='text-right'></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='page-style screen6'>
                    <div className='super-man' depth="0.4"></div>
                    <div className='super-man-text' depth="0.2"></div>
                </SwiperSlide>
                <SwiperSlide className='page-style screen7'>
                    <video src='https://lzj.maliao.online/zm.mp4' muted={true} preload="true" id="zmVideo"></video>
                </SwiperSlide>
                <SwiperSlide className='page-style screen8' onMouseMove={e => this.moveTextScreen(e)}>
                    <video src='https://lzj.maliao.online/superman-video1.mp4' muted={true} loop={true} autoPlay={true}></video>
                    <div className='bg'></div>
                    <div className='mask'></div>
                    <div className='superman-text' style={{
                        transform: 'rotateY(' + this.state.textVal + 'deg)'
                    }}>
                        <div className='text-left'></div>
                        <div className='text-center'></div>
                        <div className='text-right'></div>
                    </div>
                </SwiperSlide>
                <SwiperSlide className='page-style screen9'></SwiperSlide>
                <SwiperSlide className='page-style screen10'></SwiperSlide>
                <SwiperSlide className='page-style screen11'></SwiperSlide>
                <SwiperSlide className='page-style screen12'></SwiperSlide>
                <SwiperSlide className='page-style screen13'></SwiperSlide>
                <SwiperSlide className='page-style screen14'></SwiperSlide>
                <SwiperSlide className='page-style screen15'></SwiperSlide>
                <SwiperSlide className='page-style screen16'></SwiperSlide>
                <SwiperSlide className='page-style screen17'></SwiperSlide>
                <SwiperSlide className='page-style screen18'></SwiperSlide>
                <SwiperSlide className='page-style screen19'></SwiperSlide>
                <SwiperSlide className='page-style screen20'>
                    <div className='thankyou-wrap'>
                        <div className='mask-before'>
                            <div className='img-comm2 img-left' depth="0.1"></div>
                            <div className='img-comm img-center' movey="0" rotate="0" depth="0.2"></div>
                            <div className='img-comm2 img-right' depth="0.3"></div>
                            <div className='mask'></div>
                        </div>
                        <div className='thankyou-text' depth="0.4"></div>
                    </div>
                    <div className='color-blcok'></div>
                </SwiperSlide>
            </Swiper>
            </>
        );
    }
}

export default IndexView;